export default {
  connections: {
    'minimum-term-disclaimer': `Rate limit cannot be decreased below the selected speed at time of terming.`,
    'transit-configuration': `Connection Details for ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'enable-bgp': `Enable BGP`,
    'ix-details': `Connection Details for ${process.env.VUE_APP_EN_PRODUCTNAME_IX} Service`,
    'request-higher-speed': `Request higher speed`,
  },
  general: {
    'open-new-tab': ' - Opens in a new tab',
    'g2-review': `We would appreciate a few moments of your time to review ${process.env.VUE_APP_EN_COMPANYINFO_NAME} through the G2 review service. We will share your email address with G2. See G2’s <a href="https://www.g2.com/static/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.g2.com/static/terms" target="_blank">Terms of Use</a>.`,
    'g2-review-title': `Review ${process.env.VUE_APP_EN_COMPANYINFO_NAME}`,
    review: 'Review',
    'dont-review': `Don't Review`,
  },
  aws: {
    'connection-details': `Connection Details for AWS Service`,
  },
  menu: {
    'quoting-tool': 'Quoting Tool',
    'in-app-tools': 'In-App Tools',
    'additional-tools': 'Additional Tools',
    'bug-report': 'Bug Report',
  },
  'billing-markets': {
    'change-registration-number-info': 'Contact your Account Manager or Customer Success Manager to change your Company Registration Number associated with the purchasing entity.',
    'change-legal-name-info': 'Contact your Account Manager or Customer Success Manager to change your Company Legal Name associated with the purchasing entity.',
    'view-megaport-contacts': 'View Megaport Contact Details',
  },
  tooltips: {
    'transit-bgp-config': `Enable this option to enter BGP routing information for the B-End of your ${process.env.VUE_APP_EN_PRODUCTNAME_TRANSIT_VXC} connection.`,
    'transit-prefixes': 'List of publicly assigned IPv4 or IPv6 networks to be advertised.',
  },
  alibaba: {
    'connection-details': `Connection Details for Alibaba Service`,
  },
  amsix: {
    'connection-details': `Connection Details for AMS-${process.env.VUE_APP_EN_PRODUCTNAME_IX} Service`,
  },
  salesforce: {
    configuration: `Connection Details for Salesforce Service`,
  },
  outscale: {
    'connection-details': `Connection Details for Outscale Service`,
  },
  ibm: {
    'connection-details': `Connection Details for IBM Cloud Service`,
  },
  'service-status': {
    'start-timezone': 'Start ({thing})',
    'end-timezone': 'End ({thing})',
  },
  'cancel-reason': {
    'proof-of-concept': 'Proof of Concept / Test',
    other: `Reason not Listed`,
    'moved-dc': `Changing Data Centers`,
  },
  marketplace: {
    'destinations-count': `1 Destination | {count} Destinations`,
    'destination-count': `Destination Count`,
    'pluralize-destinations': `Destination | Destinations`,
    'create-vxc': `Create a new ${process.env.VUE_APP_EN_PRODUCTNAME_VXC} to this destination`,
    'no-services-title': `No available services`,
    'no-services-message': `You have no deployed services available in this region. Typically this could be because of a network region mismatch or because services that would otherwise be eligible have untagged connections. Click OK to return to your services and create one.`,
    'no-deployed-services': `You have no deployed services available in this region. Click to return to your services and create one.`,
    'select-services-destination': `Select services that this destination can provide`,
    'private-destinations': `Greyed services are not marked as public destinations. To enable them, change the ${process.env.VUE_APP_EN_PRODUCTNAME_MARKETPLACE} visibility option for the service. Only public services will be visible to others viewing your profile.`,
    'duplicate-name-warning': `This can cause confusion for people wanting to connect to your service.<br/><br/>Are you sure you want to save?`,
    'select-source': 'Select Source',
  },
}
