import { deepClone, convertProductType, convertSpeed, formatMveVendorName } from '@/helpers.js'
import captureSentryError from '@/utils/CaptureSentryError.js'

import * as globals from '@/Globals.js'
import buildProviders from '@/providers/build'

import Vue from 'vue'
import sdk from '@megaport/api-sdk'
import { formatLocation, staggerPolling, fixDealUid, fixNullVlan, isConnection, isService, iterateServiceConnections, processIXParams, processVXCParams } from './servicesLogic.js'

// Timeout periods in seconds for the different relevant provisioning statuses
const timeoutPeriods = {
  [globals.G_PROVISIONING_DEPLOYABLE]: 30, // Passed validations and the back end is working on deployment
  [globals.G_PROVISIONING_CONFIGURED]: 60, // Ready to go but awaiting user action (approval, physical connection)
  // Disabled for now as performance in the portal is not great.
  // NEW: 60 * 5, // We probably won't see this status at the front end, but just to be sure...
  // LIVE: 60 * 5 * timeoutMultiplier,
  // CANCELLED: 60 * 30 * timeoutMultiplier,
  // CANCELLED_PARENT: 60 * 30 * timeoutMultiplier,
  // We don't do periodic updates for FAILED, DECOMMISSIONED, TERMINATED, or DESIGN (not on the server yet).
}

// Initial state
const coreState = {
  locations: [], // Loaded once on init
  regions: [], // Loaded once on init
  partnerPorts: {}, // Loaded once on init, then occasionally updated
  transitEnabledMarkets: [], // Megaport Internet enabled markets for MCRs and Ports
  services: [],
  serverCarts: [],
  fetchServiceTimeouts: {}, // Timeout IDs for each service
  refreshPortsMetadataTimeout: null, // Timeout ID for refreshPortsMetadata
  servicesReady: false,
  locationsReady: false,
  pollingDisabled: false,
  collapsedServiceUids: [],
}

const coreGetters = {
  /**
   * Determine if a port has untagged services by checking whether their relevant vlan value is either -1 or null
   *
   * Note that although this is essentially a stateless utility method,
   * we keep it here since it is closely tied to the services.
   *
   * THE DEFINITIVE UNTAGGED SERVICE GUIDE
   *
   * POST/PUT:
   *    null -> untagged
   *    -1   -> untagged
   *    0 -> tagged (random vlan)
   *    >=0  -> tagged w/ this vlan
   *
   * GET:
   *    -1   -> error
   *    null -> untagged
   *    >0  -> tagged w/ this vlan
   */
  doesPortHaveUntaggedServices: () => port => {
    // Nothing to look at if both of these keys return a falsy value
    if (!port.associatedVxcs && !port.associatedIxs) return false

    // Create a list including all associated VXCs and IXs
    const associatedServices = [].concat(port.associatedVxcs, port.associatedIxs)

    const numberOfAssociatedServices = associatedServices.length

    // Nothing to look at if the port doesn't have any associated services
    if (numberOfAssociatedServices === 0) return false

    // Iterate through the port's associated services and check if their relevant vlan value
    // is either -1 or null, which would signal an untagged service.
    for (let i = 0; i < numberOfAssociatedServices; i++) {
      // Only check services not in DESIGN
      if (associatedServices[i].provisioningStatus !== globals.G_PROVISIONING_DESIGN) {
        // If the service is an IX, the relevant vlan value is found at the top-most level.
        if (
          associatedServices[i].productType === globals.G_PRODUCT_TYPE_IX &&
          (associatedServices[i].vlan === -1 || associatedServices[i].vlan === null)
        ) {
          return true
        }

        // If the service's aEnd productUid matches the parent port's productUid,
        // the relevant vlan value is found in the aEnd object.
        if (
          associatedServices[i].aEnd?.productUid === port.productUid &&
          (associatedServices[i].aEnd.vlan === -1 || associatedServices[i].aEnd.vlan === null)
        ) {
          return true
        }

        // If the service's bEnd productUid matches the parent port's productUid,
        // the relevant vlan value is found in the bEnd object.
        if (
          associatedServices[i].bEnd?.productUid === port.productUid &&
          (associatedServices[i].bEnd.vlan === -1 || associatedServices[i].bEnd.vlan === null)
        ) {
          return true
        }
      }
    }
    return false
  },

  /**
   * Get the target ports relevant to the user, for use in establishing connections.
   */
  targetPorts: state => {
    // Add my services first with their associated data, then the standard partner ports.
    const allPortLikeServices = [...state.services, ...Object.values(state.partnerPorts)]

    // Note that for the case of a user where they have cloud type ports and they are logged in as that user
    // we need to keep both the cloud version and local version, so only treat as duplicate if both the
    // productUid and connectType are the same (see ENG-7764).
    const deduplicatedPortLikeServices = allPortLikeServices.filter((service, index, self) => {
      return index === self.findIndex(item => item.productUid === service.productUid && item.connectType === service.connectType)
    })

    return deduplicatedPortLikeServices
  },

  /**
   * Gets any extra metadata that needs to be updated on the port so that all the metadata is up to date.
   *
   * The reason it tries to work out what changed is so that state mutations are kept to a minimum.
   *
   * This needs to be called whenever any services are updated for any reason since the calculated values
   * depend on other lagged ports, the rate limits of any associated VXCs, etc.
   */
  getPortEnhancements: (state, getters, rootState) => port => {
    let returnObject = {}


    // Patch in _location from the new field locationDetail if it exists,
    // otherwise, look up the location from the locations store.
    // This is for in design services essentially, so ok with it being a bit inefficient.
    // NOTE: Potentially could move this out of the store in the future, to its own helper function
    // and use it when services are added...
    const locationDetail = port.locationDetail
    if (locationDetail) {
      const market = port.locationDetail?.market ?? port.market
      returnObject._location = formatLocation(locationDetail, market, locationDetail.city, locationDetail.country)
    } else {
      const location = state.locations.find(loc => loc.id === port.locationId)
      // For some reason location can be null here, so we need to check we have found a location.
      // The code through the portal handles missing _location and displays "Unknown Location" so we don't need to worry if it's missing.
      if (location) {
        returnObject._location = formatLocation(location, location.market, location.address?.city, location.address?.country)
      }
    }


    // Sub-UID
    // Next, we calculate the port's subUid (first eight characters of the port's productUid) internally. We then compare this
    // against the value the port may have in _subUid. If the latter is undefined or if they are not equal,
    // we set the value we obtained internally as the value for the port's _subUid key.
    const calcSubUid = port.productUid.slice(0, 8)
    if (port._subUid === undefined || port._subUid !== calcSubUid) {
      returnObject._subUid = calcSubUid
    }

    const isMcrOrMve = port.virtual

    // Speed
    // Next, we want to calculate the port's available speed. This will be directly the port's speed value
    // unless the port is a primary port within a LAG, in which case the available speed would be calculated
    // by aggregating the speed of all ports in the LAG. Since all ports in a LAG share the same speed,
    // this is simply a multiplication of the port's speed by the number of ports in the LAG.
    let calcAggSpeed
    let calcAggSpeedChanged = false
    let lagPorts

    // If the port is an MCR or an MVE, we can simply obtain its aggregated speed
    // from its speed or portSpeed keys since they will never be part of a LAG.
    if (isMcrOrMve) {
      calcAggSpeed = port.speed || port.portSpeed
      // If not a virtual port (MCR or MVE), we have to consider that a port which is part of a LAG
      // is assigned an aggregation ID only when deployed (this includes the primary port of the LAG).
      // So, if a port doesn't have an aggregation ID or the port has an aggregation ID but is not the primary port in the LAG,
      // we are left with the following three scenarios:
      // - The port is not part of a LAG
      // - The port is a deployed secondary port within a LAG
      // - We are dealing with an in-DESIGN lAG
    } else if (!port.aggregationId || !port.lagPrimary) {
      // If we are dealing with an in-DESIGN LAG, we have access to its port count,
      // so we use this to calculate the aggregated speed.
      if (port.lagPortCount) {
        calcAggSpeed = (port.speed || port.portSpeed) * port.lagPortCount
        // If we don't have a port count (either a port that is not part of a LAG or a secondary port within a LAG),
        // we simply obtain its aggregated speed from its speed or portSpeed keys.
      } else {
        calcAggSpeed = port.speed || port.portSpeed
      }
    } else {
      // If all the relevant if blocks above have been bypassed, we are dealing with a deployed primary port within a LAG.
      // Now, in this case, we do need to consider in-DESIGN LAGs within the LAG. So, we need to check whether we are in the presence
      // of any such cases and calculate the aggregate speed for them accordingly and add that aggregation to the primary LAG's aggregated value.
      lagPorts = state.services.filter(lagPort => lagPort.aggregationId === port.aggregationId)
      const numberOfPortsInTheLAG = lagPorts.length
      let speed = 0
      for (let i = 0; i < numberOfPortsInTheLAG; i++) {
        // Start with a multiplier of one assuming the port is not an in-DESIGN LAG
        let multiplier = 1
        // Adjust the multiplier accordingly if the port is an in-DESIGN LAG
        if (lagPorts[i].lagPortCount) {
          multiplier = lagPorts[i].lagPortCount
        }
        // Aggregate the available speed using the relevant multiplier
        speed += (lagPorts[i].speed || lagPorts[i].portSpeed) * multiplier
      }
      calcAggSpeed = speed
    }

    // Now, we can finally compare the aggregated speed we calculated with the value the port may have in _aggSpeed.
    // If the latter is undefined or if they are not equal, we set the value we calculated as the value for the port's _aggSpeed key
    // and raise a flag noting the aggregated speed has changed so that we can later trigger the relevant mutations.
    if (port._aggSpeed === undefined || port._aggSpeed !== calcAggSpeed) {
      returnObject._aggSpeed = calcAggSpeed
      calcAggSpeedChanged = true
    }

    // We then turn the aggregated speed we calculated into a formatted string
    // and do the same we did in the last step but for the _speed property.
    const calcAggSpeedString = convertSpeed(calcAggSpeed)
    if (port._speed === undefined || port._speed !== calcAggSpeedString) {
      returnObject._speed = calcAggSpeedString
    }

    // Since partner ports include "speed" rather than "portSpeed",
    // we set "speed" for this port so it can be easily concatenated with partner ports.
    const newSpeed = port.portSpeed
    if (port.speed === undefined || port.speed !== newSpeed) {
      returnObject.speed = newSpeed
    }

    // Connect Type
    // Next, we need to ensure that we always have a connectType for merging with partner ports.
    // If the port doesn't have a connection type, we determine the port's connection type internally based on the port's product type.
    let calcConnectType

    if (port.connectType === undefined) {
      switch (port.productType) {
        case globals.G_PRODUCT_TYPE_MCR2:
          calcConnectType = 'VROUTER'
          break
        case globals.G_PRODUCT_TYPE_MVE:
          calcConnectType = 'MVE'
          break
        default:
          calcConnectType = 'DEFAULT'
      }
    } else {
      calcConnectType = port.connectType
    }

    returnObject.connectType = calcConnectType

    // Title (Partner Ports)
    // Next, we need to ensure that we always have a title for merging with partner ports.
    // We just determine the port's title by looking at the port's productName.
    // We then compare this against the title the port may have. If the port doesn't have one or if it's not equal to the one we determined,
    // we set the port's title as the value we determined internally.
    const calcTitle = port.productName
    if (port.title === undefined || port.title !== calcTitle) {
      returnObject.title = calcTitle
    }

    // Marketplace Title
    // Note: may need a mechanism to keep this up to date, if leaving it to the normal update process isn't sufficient.
    // Next, we determine the marketplace title for the port if relevant.
    let calcMarketplaceTitle
    // If the marketplace data is empty, it isn't relevant.
    if (rootState.Marketplace.marketplaceData.length === 0) {
      calcMarketplaceTitle = null
    } else {
      // If we do have marketplace data, we first find the company the port is linked to.
      const mpCompany = rootState.Marketplace.marketplaceData.find(c => c.companyUid === port.companyUid)
      // If we can't find the company, the company has no services,
      // or the port is not associated to the company we found, it isn't relevant.
      if (!mpCompany || !mpCompany.services || !mpCompany.services[port.productUid]) {
        calcMarketplaceTitle = null
      } else {
        // If the port is indeed associated to the company in the marketplace data,
        // we go for the title value the port may have within the marketplace data.
        calcMarketplaceTitle = mpCompany.services[port.productUid].title || null
      }
    }

    // Now, we can finally compare the market place title we determined with the value the port may have in _marketplaceTitle.
    // If the latter is undefined or if they are not equal, we set the value we calculated as the value for the port's _marketplaceTitle key.
    if (port._marketplaceTitle === undefined || port._marketplaceTitle !== calcMarketplaceTitle) {
      returnObject._marketplaceTitle = calcMarketplaceTitle
    }

    // Owned
    // Next, we determine whether the port is owned by the company associated to the logged in user.
    // We do this by comparing this company's UID to the one the port is associated to with.
    // We then compare this against the value the port may have in _owned. If the latter is undefined
    // or if they are not equal, we set the value we determined internally as the value for the port's _owned key.
    const company = rootState.Company.data || {}
    let calcOwned = port.companyUid === company.companyUid
    if (port._owned === undefined || port._owned !== calcOwned) {
      returnObject._owned = calcOwned
    }

    // Sublags - (ports within a deployed LAG that are not the primary LAG port)
    // Next, we build a list of a LAG primary port's sublags if relevant. To determine this, we make use
    // of the logic we used above when calculating the aggregated speed, knowing that lagPorts would be an array
    // of ports only if the right conditions are met, which are the same conditions we need here.
    let calcSublags
    if (lagPorts) {
      calcSublags = lagPorts.filter(lagPort => !lagPort.lagPrimary)
    }

    returnObject._subLags = calcSublags

    // Allocated Rate Limits
    // Lastly, we determine the port's capacity and build an object containing the allocated rate limits and the utilisation percentage.
    let allocatedRateLimits = 0

    // To calculate the allocated rate limits, we build a list of all services (connections) associated to the port and iterate through it,
    // accumulating each service's rate limit as we go if relevant.
    const allConnections = [...port.associatedIxs, ...port.associatedVxcs]
    allConnections.forEach(service => {
      if (service.rateLimit) allocatedRateLimits += service.rateLimit
    })

    // We then compare the allocated rate limits we calculated with the allocated value the port may have in _capacity.
    // If the latter is undefined, if they are not equal, or if the aggregated speed has changed,
    // we set a new object containing our calculated allocated rate limits
    // and the new utilisation percentage as the value for the port's _capacity key.
    if (port._capacity === undefined || port._capacity.allocated !== allocatedRateLimits || calcAggSpeedChanged) {
      returnObject._capacity = {
        allocated: allocatedRateLimits,
        // The utilisation percentage is calculated as the ratio between allocated rate limits and aggregated speed, multiplied by 100.
        percent: Math.round((allocatedRateLimits / calcAggSpeed) * 100),
      }
    }

    return returnObject
  },

  /**
   * A list of all port-like services ordered by date of creation
   * @returns {Array} Ordered array containing all port-like services associated to the user
   */
  myPorts: state => {
    return state.services.slice().sort((a, b) => b.createDate - a.createDate)
  },

  /**
   * A list of all connections (VXCs & IXs) ordered by date of creation
   * @returns {Array} Ordered array containing all connections associated to all port-like services associated to the user
   */
  myConnections: state => {
    return state.services.reduce((connections, port) => connections.concat(port.associatedVxcs, port.associatedIxs), [])
  },

  /**
   * A list of all port-like services and all their connections (VXCs & IXs)
   * @returns {Array} Array containing all services associated to the user
   */
  allMyServices: (state, getters) => {
    return getters.myPorts.concat(getters.myConnections)
  },

  /**
   * Returns an array of mapped IX services (name, productUid, and ip_address)
   * @returns {Array.<{productName: String, productUid: String, ip_address: Array.<{address: String, resource_name: String, resource_type: String, version: Number}>}>}
   */
  myMappedIxs: (state, getters) => {
    const ixServiceList = []
    getters.myConnections.forEach(connection => {
      // Only add to the list if it is a not-in-DESIGN IX
      if (connection.productType === globals.G_PRODUCT_TYPE_IX && connection.resources) {
        ixServiceList.push({
          productName: connection.productName,
          productUid: connection.productUid,
          ip_address: connection.resources.ip_address,
        })
      }
    })
    return ixServiceList
  },

  /**
   * A collection of all port-like services associated to the user keyed by productUid.
   * This collection is being created for the quick access of port-like service data without the need of iteration
   * since this is something we currently do in several places of our codebase and is hindering the app's performance.
   */
  portUidDictionary: (state, getters) => {
    return getters.myPorts.reduce((finalObject, port) => {
      finalObject[port.productUid] = port
      return finalObject
    }, {})
  },

  /**
   * A collection of all connections associated to all port-like services associated to the user keyed by productUid.
   * This collection is being created for the quick access of port-like service data without the need of iteration
   * since this is something we currently do in several places of our codebase and is hindering the app's performance.
   */
  connectionUidDictionary: (state, getters) => {
    return getters.myConnections.reduce((finalObject, connection) => {
      finalObject[connection.productUid] = connection
      return finalObject
    }, {})
  },

  /**
   * A collection of all port-like services associated to the user and all their connections keyed by productUid.
   * This collection is being created for the quick access of port-like service data without the need of iteration
   * since this is something we currently do in several places of our codebase and is hindering the app's performance.
   */
  allServicesUidDictionary: (state, getters) => {
    return getters.allMyServices.reduce((finalObject, service) => {
      finalObject[service.productUid] = service
      return finalObject
    }, {})
  },

  /**
   * Find the specified port (includes partner ports and avoids duplication) by productUid.
   */
  findPort: (state, getters) => productUid => {
    // Rather than going through all the services and partner ports from the start and doing all the de-duplication,
    // just try to find the port in the port dictionary and if is isn't there, return the relevant partner port.
    return getters.portUidDictionary[productUid] ?? state.partnerPorts[productUid]
  },

  /**
   * Find the specified service (port or connection) by productUid (includes partner ports and avoids duplication) by productUid
   */
  findService: (state, getters) => productUid => {
    // Rather than going through all the services and partner ports from the start and doing all the de-duplication,
    // just try to find the service in the service dictionary and if is isn't there, return the relevant partner port.
    return getters.allServicesUidDictionary[productUid] ?? state.partnerPorts[productUid]
  },

  /**
   * Get the location data associated to a specific locationId
   */
  getLocationById: state => locationId => {
    return state.locations.find(location => location.id === locationId)
  },

  getServicesById: (state, getters) => serviceId => {
    const allServicesIncludingPartnerPorts = [].concat(getters.targetPorts, getters.myConnections)
    return allServicesIncludingPartnerPorts.filter(service => service.productId === serviceId)
  },

  filteredLocations: (state, getters, rootState, rootGetters) => {
    // If they are a managed account user, they get the locations defined by the partner (or distributor).
    // If they are a partner managed by a distributor, they get the locations defined by the distributor.
    if (rootGetters['Auth/isManagedAccount'] || rootGetters['Auth/isDistributorPartnerAccount']) {
      return state.locations.filter(loc => loc.marketEnabled)
    }

    return state.locations
  },

  /**
   * Determines whether to show a lock icon or not on a service
   */
  showLock: (state, getters, rootState, rootGetters) => {
    // Start by checking whether the feature has been disabled
    if (buildProviders.disabledFeatures.locking) return false
    // Show the lock icon if the user is a Company Admin
    if (rootGetters['Auth/hasAuth']('company_admin')) return true
    // Show the lock icon if any of the connections associated to the user is locked
    if (getters.myConnections.find(connection => connection.locked || connection.adminLocked)) return true
    // Do not show the lock icon otherwise
    return false
  },

  /**
   * Determines whether a port can be connected to by VXC
   */
  canPortBeConnectedToByVxc: (state, getters) => (a, b) => {
    // Exit if either end is missing
    if (!a || !b) return false

    // Try to get the location data for both ends in case it is missing
    if (!a.locationInfo) a.locationInfo = getters.getLocationById(a.locationId) || {}
    if (!b.locationInfo) b.locationInfo = getters.getLocationById(b.locationId) || {}

    // Can't connect if both ends are exactly the same object
    if (a === b) return false

    // Can't connect if it's the same product - i.e. same A and B ends
    if (a.productUid === b.productUid) return false

    // Can't form a connection if either end has untagged services
    if (getters.doesPortHaveUntaggedServices(a) || getters.doesPortHaveUntaggedServices(b)) return false

    // Can't connect if they are in a different network region
    if (a.locationInfo.networkRegion !== b.locationInfo.networkRegion) return false

    // Can't connect if either end is unavailable due to status
    const aEndIsUnavailable = [globals.G_PROVISIONING_DESIGN_DEPLOY, globals.G_PROVISIONING_NEW].includes(a.provisioningStatus)
    const bEndIsUnavailable = [globals.G_PROVISIONING_DESIGN_DEPLOY, globals.G_PROVISIONING_NEW].includes(b.provisioningStatus)
    if (aEndIsUnavailable || bEndIsUnavailable) return false

    // Can't connect if VXC connections are not permitted in the A end
    if (a.vxcPermitted === false) return false

    return true
  },

  /**
   * Getter for returning a function that returns a list of valid move destinations for a given portlike
   */
  getValidMoveTargets: state => source => {
    return state.services.filter(port => source._location?.metro
      && port._location?.metro
      && port._location.metro === source._location.metro
      && port.productType === source.productType
      && port.provisioningStatus !== globals.G_PROVISIONING_DESIGN
      && port.productUid !== source.productUid)
  },

}

const actions = {
  /**
   * Checks if anything in the cart remains, if not, will clear it out of localStorage
   *
   * @param {Object} context - The Vuex store context object.
   */
  cartCleanup(context) {
    // If we do need to clean up the cart, we clear everything except services that are in DESIGN or have in DESIGN connections.
    const companyUid = context.rootGetters['Company/companyUid']
    const currentCartObj = JSON.parse(localStorage.getItem(`_mpCart_${companyUid}`)) || []

    let hasDesignedItems = false

    for (const cartService of currentCartObj) {
      const service = context.state.services.find(p => p.productUid === cartService.productUid)
      // Terminate current iteration and continue with the next one if not a port-like service (MEGAPORT, MCR, MVE)
      if (!service) continue

      if (service.provisioningStatus === globals.G_PROVISIONING_DESIGN) {
        hasDesignedItems = true
        break
      }

      const designedConnections = [...service.associatedVxcs, ...service.associatedIxs].filter(connection => connection.provisioningStatus === globals.G_PROVISIONING_DESIGN)
      if (designedConnections.length) {
        hasDesignedItems = true
        break
      }
    }
    // If the service is not in DESIGN or has no in DESIGN connections, delete the configuration.
    if (!hasDesignedItems) {
      context.dispatch('clearCartFromLocalStorage')
    }
  },
  /**
   * Updates an existing connection in the store.
   * This function is for when we've already loaded a connection from the API and we want to update it in place.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} connection - The connection object to update.
   */
  updateExistingConnection(context, connection) {
    // If we fetch a connection and the status has updated to failed or decommissioned, we remove it from the store and do no more updates.
    // This endpoint can't filter them out, so we have to do it here.
    if ([globals.G_PROVISIONING_FAILED, globals.G_PROVISIONING_DECOMMISSIONED].includes(connection.provisioningStatus)) {
      context.dispatch('removeServiceOrConnection', {
        productUid: connection.productUid,
        cartCleanup: false,
      })

      return
    }

    // Build a list of all connections
    const existingConnections = context.getters.myConnections.filter(({ productUid }) => productUid === connection.productUid)
    if (existingConnections.length === 0) return

    for (const existingConnection of existingConnections) {
      // If this connection is awaiting internal approval and the added object is awaiting external
      // approval, we don't want to have the VXC approval set.
      if (
        existingConnection.vxcApproval?.status === globals.G_PROVISIONING_PENDING_INTERNAL &&
          connection.vxcApproval?.status === globals.G_PROVISIONING_PENDING_EXTERNAL
      ) {
        delete connection.vxcApproval
      }

      // As the new connection comes from the API, we need to fix the vlan
      fixNullVlan(connection)

      // This logic is for handling very old VXC and IX's that don't have a rateLimit set. As of the time of writing this comment
      // there is only 1 LIVE VXC that doesn't have a rateLimit set, so this is a very rare edge case.
      // But apparently there is over 2000 decommisioned VXCs that don't have a rateLimit set :( so we need to handle this.
      // The BE plans to do a DB cleanup and fix this, so it can be removed if that has happened and we no longer need to support null rateLimit.
      const isIx = connection.productType === globals.G_PRODUCT_TYPE_IX

      if (isIx) {
        connection.rateLimit = connection.rateLimit || (connection.parentPortUid ? context.getters.findService(connection.parentPortUid[0]).portSpeed : null) || 1000
      } else {
        connection.rateLimit = connection.rateLimit || 1000
      }

      // Update existing connection in the store
      const mutationHandler = isIx ? 'updateIX' : 'updateVXC'
      const newConnection = { ...existingConnection, ...connection }

      newConnection.parentPortUid.forEach(parentPortUid => {
        context.commit(mutationHandler, {
          service: context.getters.findPort(parentPortUid),
          connection: newConnection,
        })
      })
    }
  },

  /**
   * Adds a connection to an existing service.
   * Updates the associatedIxs or associatedVxcs array for the service to include the new connection
   *
   * @param {Object} context - The Vuex context object.
   * @param {Object} payload - The payload object containing the connection and service.
   * @param {Object} payload.connection - The connection object to be added.
   * @param {Object} payload.service - The service object to which the connection will be added.
   */
  addConnectionToService(context, { connection, service }) {
    const { productType, aEnd, bEnd } = connection

    if (!isConnection(productType)) return

    const connectionClone = deepClone(connection)
    const isIx = productType === globals.G_PRODUCT_TYPE_IX

    // We initialise the array with the aEnd service which is passed into this method.
    const services = []

    // Collect the port objects for the connection
    if (isIx) {
      services.push(service)
    } else {
      // We pass in the aEndService to this function.
      if (!aEnd.ownerUid) {
        connectionClone.aEnd.ownerUid = service.companyUid
      }

      services.push(service)

      // We can't use findPort() here as it'll return the partnerPort, which we don't want to include in the services.
      const bEndService = context.getters.portUidDictionary[bEnd.productUid]

      if (bEndService) {
        if (!bEnd.ownerUid) {
          connectionClone.bEnd.ownerUid = bEndService.companyUid
        }

        services.push(bEndService)
      }
    }


    // Set an array of parent service uids so we can support the lookup for vxc and ix
    connectionClone.parentPortUid = services
      .filter(port => port)
      .map(port => port.productUid)


    const dealUid = fixDealUid(connection.dealUid)
    const connectionTypeArray = isIx ? globals.G_CONNECTION_FIELD_IX : globals.G_CONNECTION_FIELD_VXC
    const connectionKey = isIx ? 'ix' : 'vxc'
    const mutationHandler = isIx ? 'addAssociatedIx' : 'addAssociatedVxc'

    // Add the connection to the service
    services.forEach(thisService => {
      if (thisService[connectionTypeArray].find(({ productUid }) => productUid === connection.productUid)) return
      context.commit(mutationHandler, {
        service: thisService,
        [connectionKey]: {
          ...connectionClone,
          dealUid,
        },
      })
    })
  },

  /**
   * Edits a connection in the store.
   * This is used on the edit pages in the portal for connections. Will do a PUT request to the API to update the connection
   * and then fetch the new data and update the store with it.
   *
   * @param {Object} context - The Vuex context object.
   * @param {Object} connection - The connection object to be edited.
   * @param {Object} options - The options for editing the connection.
   * @param {boolean} options.updateTerm - Whether to update the term or not. Default is false.
   * @returns {Promise<void>} - A promise that resolves when the connection is edited.
   */
  async editConnection(context, { connection, updateTerm = false }) {
    const { productUid, productType, productName } = connection
    // VXC and IX have different parameters - so check and process them appropriately.
    const isIX = productType === globals.G_PRODUCT_TYPE_IX
    const params = isIX ? processIXParams(connection) : processVXCParams(connection, context.rootState.Company.data.companyUid, { updateTerm })

    try {
      await sdk.instance.product(productUid).update(params)
      context.commit(
        'Notifications/notifyGood',
            { title: window.mpApp.$t('services.port-like-updated-message', { name: productName, productType }) },
            { root: true }
      )
    } catch (err) {
      context.commit(
        'Notifications/notifyBad',
            {
              title: window.mpApp.$t('general.error-updating', { thing: `${productType}: ${productName}` }),
              message: err.data?.message || window.mpApp.$t('general.no-info'),
            },
            { root: true }
      )
    } finally {
      // Either way we want to make sure we re-fetch the service to ensure the state is refreshed.
      await context.dispatch('fetchServiceOrConnection', {
        productUid,
        incResources: true,
        periodic: true,
      })

      // Reset the pricing cache in case it has changed to a different pricing model
      sdk.instance.resetPriceBookCache()
    }
  },

  /**
   * Removes a service *or* connection from the store, with the option to remove it from the Cart also
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} payload - The payload object.
   * @param {string} payload.productUid - The UID of the service or connection to be removed.
   * @param {boolean} payload.cartCleanup - Indicates whether to clean up services from the cart as well
   * @returns {void}
   */
  removeServiceOrConnection(context, { productUid, cartCleanup = true }) {
    // Make sure we don't continue trying to refresh the service we are about to remove
    context.commit('removeFetchServiceTimeout', productUid)

    // Look for the service in the main services array to check if it is a MEGAPORT, MCR, or MVE.
    const serviceIndex = context.state.services.findIndex(port => port.productUid === productUid)

    // If found above, must be a service
    const foundService = serviceIndex !== -1

    // If a service (MEGAPORT, MCR, or MVE), we first find and remove
    // all of its associated connections (VXCs and IXs) and then delete the service itself.
    if (foundService) {
      const service = context.state.services[serviceIndex]
      // This is required to remove the associated services for *private* VXCs from existing services that may still be live.
      iterateServiceConnections(service, connection => context.dispatch('removeServiceOrConnection', { productUid: connection.productUid }))
      context.commit('deleteService', serviceIndex)
    } else {
      // If we're deleting a connection, find it within the parent services associated services
      const connection = context.getters.connectionUidDictionary[productUid]

      // If we're trying to delete a connection that doesn't actually exist, exit early.
      if (!connection) return

      connection.parentPortUid.forEach(parentPortUid => {
        const service = context.getters.portUidDictionary[parentPortUid]
        const vxcIndex = service.associatedVxcs.findIndex(vxc => vxc.productUid === productUid)
        if (vxcIndex !== -1) {
          context.commit('removeAssociatedVxc', {
            service,
            index: vxcIndex,
          })
        } else {
          // if not found above, it must be IX - else is so we don't do this if we already found it
          const ixIndex = service.associatedIxs.findIndex(ix => ix.productUid === productUid)
          if (ixIndex > -1) {
            context.commit('removeAssociatedIx', {
              service,
              index: ixIndex,
            })
          }
        }
      })
    }

    context.dispatch('refreshServiceMetadata')

    // This is set from the *delete* actions in the store, so we can use it to determine if we need to clean up the cart.
    // This will check if any services remain in design and delete the cart from localStorage if not.
    if (cartCleanup) {
      context.dispatch('cartCleanup')
    }
    context.dispatch('refreshServiceMetadata')
  },

  /**
   * Loads services from the cart and performs necessary operations for each service.
   * The difference between this function and loadServicesFromAPI is that the list of services passed in is from the cart,
   * which means they aren't ordered yet. So we need to check what does and does not exist in the store and add them accordingly.
   * This needs to be called *after* the API services have been loaded, as the API version will reset the services list to []
   *
   * @param {Object} context - The Vuex store context object.
   * @param {Array} listOfServices - The list of services to be loaded.
   * @returns {void}
   */
  loadServicesFromCart(context, listOfServices) {
    listOfServices.forEach(service => {
      const existingService = context.getters.portUidDictionary[service.productUid]

      // If the service doesn't exist, we can add it to the services array
      if (!existingService) {
        // This code handles old stashed carts that have been saved with falsy parentPortUid values.
        // We no longer allow this in the new code, but need to support the old cart format :(
        iterateServiceConnections(service, connection => {
          // In previous versions of the cart parentPortUid is a string value on IX's, but we've converted all to be an array for consistency
          // in the new version, so here we have to handle that edge case.
          if (!Array.isArray(connection.parentPortUid)) {
            context.commit('setParentPortUidOnConnection', { connection, parentPortUid: [connection.parentPortUid] })
          }

          context.commit('setParentPortUidOnConnection', { connection, parentPortUid: connection.parentPortUid.filter(Boolean) })
        })

        context.commit('addService', service)
      } else {
        // The service already exists, so we can check if all the connections on the service also exist
        // and if they don't, we can add them to the service.
        iterateServiceConnections(service, (connection, connectionTypeArray) => {
          const foundInConnections = existingService?.[connectionTypeArray]?.some(conn => conn.productUid === connection.productUid)
          if (!foundInConnections) {
            context.dispatch('addConnectionToService', { connection, service: existingService })
          }
        })
      }
    })
  },

  /**
   * Loads services into the store.
   * This loads services that were fetched from the API, this is expected to be called before loadServicesFromCart, as this function will clear the cart to do
   * a full refresh on the stores service list.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Array} listOfServices - The list of services to load.
   * @returns {void}
   */
  loadServicesFromAPI(context, listOfServices) {
    context.commit('clearServices')

    // First we want to add each service to the services array and set up a periodic fetch for each of them.
    // This used to be done with mutations, but building this services array and setting it once at the end is faster.
    const services = []
    listOfServices.forEach(service => {
      // Process data with required fixes for each service
      if (service.productType === globals.G_PRODUCT_TYPE_MVE) {
        service.vendor = formatMveVendorName(service.vendor)
      }

      // Iterate the connections and fix the vlan + assign parentPortUid (which is used to look up parent service quickly from a connection)
      iterateServiceConnections(service, connection => {
        fixNullVlan(connection)
        if (!connection.parentPortUid) {
          connection.parentPortUid = []
        }

        connection.parentPortUid.push(service.productUid)

        // Setup polling on the connection if required
        const nextCheckTimeout = timeoutPeriods[connection.provisioningStatus]
        if (nextCheckTimeout) {
          context.dispatch('periodicallyRefreshService', { serviceUid: connection.productUid, incResources: true, nextCheckTimeout: staggerPolling(nextCheckTimeout) })
        }
      })

      services.push(service)

      // Set the relevant timeout period if applicable
      const nextCheckTimeout = timeoutPeriods[service.provisioningStatus]

      // Setup polling on the service if required
      if (nextCheckTimeout) {
        context.dispatch('periodicallyRefreshService', { serviceUid: service.productUid, incResources: true, nextCheckTimeout: staggerPolling(nextCheckTimeout) })
      }
    })

    context.commit('setServices', services)
  },

  // Fetch transit-enabled markets for Ports and MCRs (MVE is transit-enabled in all markets offering MVEs)
  async fetchTransitEnabledMarkets(context) {
    const { markets } = await sdk.instance.priceBook().transitMarkets({ aConnectTypes: ['DEFAULT', 'VROUTER'] })

    context.commit('setTransitEnabledMarkets', markets)
  },
  /**
   * Load the partner ports. This also has the side effect of loading
   * the locations store and assigning the location information. Called once
   * during init and periodically to keep things refreshed.
   *
   * @param {object} context (store context)
   */
  loadPartnerPorts(context) {
    sdk.instance
      .lists('partnerPorts')
      .then(async listOfPartnerPorts => {
        const company = context.rootState.Company.data || {}

        if (!context.state.locationsReady) {
          await context.dispatch('loadLocations')
        }

        const mappedPorts = listOfPartnerPorts.map(port => {
          // For FranceIX marketplace connections, we intercept and change the connect type
          if (port.companyUid === globals.FRANCEIX_UID && port.connectType === 'DEFAULT') {
            port.connectType = 'FRANCEIX'
          }

          const foundLocation = context.state.locations.find(loc => loc.id === port.locationId)

          let calcMarketplaceTitle
          const mpCompany = context.rootState.Marketplace.marketplaceData.find(c => c.companyUid === port.companyUid)
          if (!mpCompany || !mpCompany.services || !mpCompany.services[port.productUid]) {
            calcMarketplaceTitle = port.title
          } else {
            calcMarketplaceTitle = mpCompany.services[port.productUid].title || port.title
          }

          // Need to enhance the port information so that it has a structural match suitable for the
          // matching with Services in targetPorts
          return {
            // Workaround for ENG-5333 which happened for any company - they would get an extra record that
            // appeared to belong to them because there was a company which had no company name, so it
            // was grabbing the company name from the logged in user.
            companyName: typeof port.companyName === 'string' ? port.companyName : company.name,
            companyUid: port.companyUid || company.companyUid,
            connectType: port.connectType,
            lagId: port.lag_id, // Note change of field name
            aggregationId: port.aggregation_id, // Note change of field name
            lagPrimary: port.lag_primary, // Note change of field name
            locationId: port.locationId,
            productUid: port.productUid,
            rank: port.rank,
            speed: port.speed,
            maxVxcSpeed: port.maxVxcSpeed,
            // We no longer use [DZ-*] to infer the Diversity Zone, but for now we still need to strip from the title
            // TODO: Remove once the data has been cleaned up
            title: port.connectType === 'AWSHC' ? port.title.replace(/ ?\[DZ-(RED|BLUE)]/, '') : port.title,
            vxcPermitted: port.vxcPermitted,
            _location: foundLocation,
            virtual: port.virtual || port.connectType === 'VROUTER',
            buyoutPort: false, // Irrelevant but added for service merge
            associatedIxs: null,
            associatedVxcs: null,
            provisioningStatus: null,
            _aggSpeed: port.speed,
            _marketplaceTitle: calcMarketplaceTitle,
            marketplaceVisibility: null, // For consistency with previous logic
            _speed: company.companyUid ? convertSpeed(port.speed) : ' ',
            _subUid: port.productUid.slice(0, 8),
            _owned: port.companyUid === company.companyUid,
            diversityZone: port.diversityZone,
          }
        })

        // In order to be able to present LAGs in the marketplace, we need to sort through the available ports
        // and aggregate the speeds. To avoid having to go through the entire array multiple times, we will collect
        // the required information first and then slot it in. This won't fully work until the LAG ids are globally
        // unique as per ENG-6872.
        const lagSpeeds = {}
        for (const port of mappedPorts) {
          if (port.aggregationId) {
            if (lagSpeeds[port.aggregationId]) {
              lagSpeeds[port.aggregationId] = lagSpeeds[port.aggregationId] + port.speed
            } else {
              lagSpeeds[port.aggregationId] = port.speed
            }
          }
        }
        for (const port of mappedPorts) {
          if (port.lagPrimary) {
            port._aggSpeed = lagSpeeds[port.aggregationId]
            port._speed = convertSpeed(port._aggSpeed)
          }
        }
        context.commit('setPartnerPorts', mappedPorts)
      })
      .catch(e => {
        captureSentryError(e)
      })
  },

  /**
   * Load the locations and save them in the state. Called during initialization and periodically
   * as a side effect of loading the partner ports. Since it makes an async call
   * to the API and we don't want to complete the method until it has been processed,
   * we return a promise and resolve when the time is right.
   *
   * @param {object} context (store context)
   */
  async loadLocations(context) {
    try {
      const listOfLocations = await sdk.instance.lists('locations')
      let filteredLocationIds = null

      // If managed, get filtered enabled markets
      if (context.rootGetters['Auth/isManagedAccount'] || context.rootGetters['Auth/isDistributorPartnerAccount']) {
        const filteredLocations = await sdk.instance.enabledMarkets()
        filteredLocationIds = filteredLocations.map(loc => loc.id)
      }

      const mappedLocations = listOfLocations.map(location => ({
        ...location,
        formatted: {
          short: `${location.name}, ${location.address.city}`,
          long: `${location.name}, ${location.address.city}, ${location.address.country}`,
        },
        ...(filteredLocationIds && { marketEnabled: filteredLocationIds.includes(location.id) }),
      }))
      context.commit('setLocations', mappedLocations)
    } catch (error) {
      captureSentryError(error)
      if (!error.handled) throw error

    } finally {
      context.commit('setReadyStatus', { key: 'locationsReady', status: true })
    }
  },

  /**
   * Loads all the services for the user. It does this by loading all the ports and then
   * using loadServices to load all the services associated with those ports. It then loads
   * the configuration from local storage to ensure that the config is reflected in the state.
   *
   * @param {object} context (store context)
   */
  async getMyServices(context) {
    try {
      context.commit('setReadyStatus', { key: 'servicesReady', status: false })

      const listOfServices = await sdk.instance.ports([
        globals.G_PROVISIONING_NEW,
        globals.G_PROVISIONING_DESIGN,
        globals.G_PROVISIONING_DEPLOYABLE,
        globals.G_PROVISIONING_CONFIGURED,
        globals.G_PROVISIONING_LIVE,
        globals.G_PROVISIONING_CANCELLED,
        globals.G_PROVISIONING_CANCELLED_PARENT,
      ])

      // Wait for the services to be loaded
      await context.dispatch('loadServicesFromAPI', listOfServices)

      // Load the local cart configuration once the services have been loaded
      await context.dispatch('loadCartFromLocalStorage')

      // Refresh the service metadata *once* after the services have been loaded
      await context.dispatch('actuallyRefreshServiceMetadata')
    } finally {
      context.commit('setReadyStatus', { key: 'servicesReady', status: true })
    }
  },

  /**
   * Get the details of a service or connection. This cannot be a getter because
   * it changes the provisioning status and gets the data from the API. Requires that the
   * service or connection is already known.
   *
   * @param {object} context (store context)
   * @param {string} uid - service or connection uid
   * @param {boolean} incResources - whether to include resources in the returned list.
   * @param {boolean} periodic - if set, then bypass things that change status to loading etc.
   */
  async fetchServiceOrConnection(context, { productUid, incResources = false, periodic = false }) {
    // Find the service from all the services and their associated VXCs and IXs
    const existingProduct = context.getters.allServicesUidDictionary[productUid]
    if (!existingProduct) throw new Error(`Service ${productUid} not found when fetching`)

    const existingProvisioningStatus = existingProduct.provisioningStatus

    // Don't attempt to load the service from the API if it's in DESIGN since it wouldn't be on the server yet
    if (existingProvisioningStatus === globals.G_PROVISIONING_DESIGN) return

    // Set the status to LOADING if it isn't a periodic fetch (we will fetch the details later and restore the actual status)
    if (!periodic) {
      context.commit('changeProvisioningStatus', {
        serviceUid: productUid,
        status: globals.G_PROVISIONING_LOADING,
      })
    } else {
      // Clear the previous timeout before we start the fetch
      context.commit('removeFetchServiceTimeout', productUid)
    }

    // Fetch the service with all of its data from the API
    try {
      const fetchedProduct = await sdk.instance
        .product(productUid)
        .get(incResources)

      if (periodic) {
        // Set the relevant timeout period if applicable
        let nextCheckTimeout = timeoutPeriods[fetchedProduct.provisioningStatus]

        // If the fetch is periodic and a timeout period applies to the service's provisioning status,
        // set up a periodic fetch based on the relevant timeout period.
        if (nextCheckTimeout) {
          context.dispatch('periodicallyRefreshService', { serviceUid: productUid, incResources, nextCheckTimeout: staggerPolling(nextCheckTimeout) })
        }

        // If we periodically refetched a service and the status didn't change, don't update the data.
        // Updating the data has horrendous performance, so we want to avoid doing it if possible.
        if (fetchedProduct.provisioningStatus === existingProvisioningStatus) return
      }

      // Handle the service if it is a MEGAPORT, MCR or MVE
      if (isService(fetchedProduct.productType)) {
        // The product endpoint returns the MVE vendor in UPPERCASE and broken by underscores (_) if multi-word.
        // This does not match the format in our Globals or in the locations, so we have to format it to match
        // what we are expecting. This is not great, but the BE says this change would represent a massive effort on their end.
        if (fetchedProduct.productType === globals.G_PRODUCT_TYPE_MVE) {
          fetchedProduct.vendor = formatMveVendorName(fetchedProduct.vendor)
        }

        context.dispatch('updateExistingService', fetchedProduct)

      } else if (isConnection(fetchedProduct.productType)) {
        context.dispatch('updateExistingConnection', fetchedProduct)

        const existingConnection = context.getters.connectionUidDictionary[fetchedProduct.productUid]
        if (!existingConnection) {
          // We need to handle the scenario where the connection has been removed during polling. So if it's not there, return early.
          // The above updateExistingConnection will handle removing it.
          return
        }

        // Because we've updated a connection, we should update the service metadata for the parent services to make sure it's up to date.
        // Instead of refreshing metadata for all services, we fetch using parentPortUid on the connection and update.
        existingConnection.parentPortUid.forEach(parentPortUid => {
          const parentServiceIndex = context.state.services.findIndex(service => service.productUid === parentPortUid)
          const parentService = context.state.services[parentServiceIndex]
          const newService = { ...parentService, ...context.getters.getPortEnhancements(parentService) }
          context.commit('updateService', {
            index: parentServiceIndex,
            newService,
          })
        })
      }

    } catch (error) {
      // Change the provisioning status back to what it was before if there was an error
      if (!periodic) {
        context.commit('changeProvisioningStatus', {
          serviceUid: productUid,
          status: existingProvisioningStatus,
        })
      }
      console.warn(error)
      throw error
    }
  },

  /**
   * Try to load any services that have been saved in the configuration in local storage.
   *
   * @param {object} context (store context)
   */
  loadCartFromLocalStorage(context) {
    try {
      const companyUid = context.rootGetters['Company/companyUid']
      const cartObj = JSON.parse(localStorage.getItem(`_mpCart_${companyUid}`)) || []

      if (!cartObj.length) return

      context.commit('updateVendorConfig', cartObj)

      context.dispatch(
        'loadServicesFromCart',
        cartObj
      )

    } catch (e) {
      console.error(e)
    }
  },

  // Put this on a timer so it doesn't fire multiple times during loading and refreshing
  refreshServiceMetadata(context) {
    if (context.state.refreshPortsMetadataTimeout) {
      clearTimeout(context.state.refreshPortsMetadataTimeout)
    }
    context.dispatch('periodicallyRefreshServiceMetadata')
  },

  // This is done via building the services array as when benchmarking, it was faster than using mutations by about 1s!
  actuallyRefreshServiceMetadata(context) {
    return new Promise(resolve => {
      let services = []
      for (const port of context.state.services) {
        const updatedData = context.getters.getPortEnhancements(port)
        services.push({ ...port, ...updatedData })
      }
      context.commit('setServices', services)
      resolve()
    })
  },

  /**
   * Edits a service.
   *
   * @param {Object} context - The Vuex context object.
   * @param {Object} service - The service object to be edited.
   * @param {Object} options - The options for editing the service.
   * @param {boolean} options.updateTerm - Whether to update the term or not. Default is false.
   * @returns {Promise<void>} - A promise that resolves when the service is edited successfully.
   */
  async editService(context, { service, updateTerm = false } = {}) {
    const existingService = context.getters.portUidDictionary[service.productUid]

    if (!existingService) return

    const payload = {
      name: service.productName,
      costCentre: service.costCentre,
      marketplaceVisibility: service.marketplaceVisibility,
      rateLimit: service.rateLimit || undefined,
      term: updateTerm ? service.contractTermMonths : null,
      dealUid: fixDealUid(service.dealUid),
    }

    // Add the BGP shutdown default if it's an MCR
    if (service.productType === globals.G_PRODUCT_TYPE_MCR2) {
      payload.bgpShutdownDefault = service.bgpShutdownDefault || false
    }

    try {
      await sdk.instance.product(service.productUid).update(payload)
      const productType = convertProductType(service.productType)

      context.commit(
        'Notifications/notifyGood',
              { title: window.mpApp.$t('services.port-like-updated-message', { name: service.productName, productType: productType }) },
              { root: true }
      )
    } catch (err) {
      context.commit(
        'Notifications/notifyBad',
                {
                  title: window.mpApp.$t('general.error-updating', { thing: service.productName }),
                  message: err.data?.message || this._vm.$t('general.unknown-error'),
                },
                {
                  root: true,
                }
      )
    } finally {
      await context.dispatch('fetchServiceOrConnection', { productUid: service.productUid })

      // Reset the pricing cache in case it has changed to a different pricing model
      sdk.instance.resetPriceBookCache()
    }
  },

  /**
   * Updates an existing service in the store.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} service - The updated service object.
   * @returns {void}
   */
  updateExistingService(context, service) {
    let existingServiceIndex = context.state.services.findIndex(port => port.productUid === service.productUid)

    // If not found, exit early - this means the service has since been removed.
    if (existingServiceIndex === -1) return

    const existingService = context.state.services[existingServiceIndex]

    // When we ask for all the ports, the result includes a null for the usageAlgorithm but when we ask
    // for a specific port, it comes in with the usageAlgorithm defined. Since we are not interested
    // in this at all at the front end, we will just delete it from the data.
    // TODO: get BE to remove this
    delete service.usageAlgorithm

    // Use existing connection data, as this action is only ever triggered from updating a service, so connections won't have changed.
    // This is done for efficiency so we don't need to process all the connections again for no reason.
    service.associatedIxs = existingService.associatedIxs
    service.associatedVxcs = existingService.associatedVxcs

    context.commit('fixLocationIds', service)

    // Merge existing service, with new service and latest port enhancements.
    const enhancements = context.getters.getPortEnhancements(service)
    const newService = { ...existingService, ...service, ...enhancements }

    context.commit('updateService', {
      index: existingServiceIndex,
      newService,
    })
  },


  /**
   * Add a service. This is used for adding a MCR, Port or MVE to the store *before* it's ordered.
   * So from the cart or the final step in ordering a service
   *
   * @param {object} context (store context)
   * @param {*} payload
   */
  addService(context, service) {
    // Bail out if it's not a Port, MCR or MVE.
    if (!isService(service.productType)) return

    // Can't add it unless it's properly formed with a productUid
    if (!service.productUid) return

    // Clone is required as this is form values from a component which get reused to add diverse pairs
    const serviceClone = deepClone(service)

    // This is a new port being added
    serviceClone.associatedVxcs = []
    serviceClone.associatedIxs = []

    context.commit('addService', serviceClone)
    context.dispatch('refreshServiceMetadata')
  },

  storeNetworkDesignLocally(context) {
    const objToStore = []
    const existingUids = []

    if (!context.rootState.Auth.data.companyUid) {
      return // We've logged out, so nothing to save
    }

    context.state.services.forEach(port => {
      if (port.provisioningStatus !== globals.G_PROVISIONING_DESIGN) return
      const portClone = deepClone(port)
      portClone.promoCode = undefined
      // ENG-2688: if the port is in DESIGN, then only include services where it is the a-End
      portClone.associatedVxcs = portClone.associatedVxcs.filter(vxc => {
        return vxc.aEnd.productUid === portClone.productUid
      })
      objToStore.push(portClone)
      existingUids.push(portClone.productUid)
      portClone.associatedVxcs.concat(portClone.associatedIxs).forEach(service => {
        existingUids.push(service.productUid)
      })
      return
    })

    context.state.services.forEach(port => {
      if (port.provisioningStatus === globals.G_PROVISIONING_DESIGN) return
      const portClone = deepClone(port)

      portClone.promoCode = undefined
      portClone.associatedVxcs.length = 0
      portClone.associatedIxs.length = 0
      port.associatedVxcs.concat(port.associatedIxs).forEach(service => {
        if (service.provisioningStatus !== globals.G_PROVISIONING_DESIGN) return
        if (existingUids.includes(service.productUid)) return

        if (service.productType === globals.G_PRODUCT_TYPE_VXC || service.productType === globals.G_PRODUCT_TYPE_CXC) {
          if (service.aEnd.productUid !== portClone.productUid) {
            return
          }
          const clonedService = deepClone(service)
          const { dealUid } = clonedService
          clonedService.dealUid = fixDealUid(dealUid)
          clonedService.promoCode = undefined
          portClone.associatedVxcs.push(clonedService)
          return
        }

        if (service.productType === globals.G_PRODUCT_TYPE_IX) {
          const clonedService = deepClone(service)
          const { dealUid } = clonedService
          clonedService.dealUid = fixDealUid(dealUid)
          clonedService.promoCode = undefined
          portClone.associatedIxs.push(clonedService)
        }
        existingUids.push(service.productUid)
      })
      if (portClone.associatedVxcs.length + portClone.associatedIxs.length > 0) return objToStore.push(portClone)
    })
    const companyUid = context.rootGetters['Company/companyUid']
    if (objToStore.length === 0) {
      const objStore = JSON.parse(localStorage.getItem(`_mpCart_${companyUid}`)) || []
      if (objStore) return // we have an item already loaded in the configuration so don't wipe it out
    }

    localStorage.setItem(`_mpCart_${companyUid}`, JSON.stringify(objToStore))
  },

  /**
   *
   * @param {Object} context
   *
   * Payload as follows:
   * @param {String} title - title to give the saved object
   * @param {String} serviceOrderUid - optional - provide if you want to update an existing configuration
   *
   * @returns {String} service order Uid
   */
  persistCartToServer(context, { title: titleParam, serviceOrderUid, suppressSuccessMessages }) {
    const title = titleParam || 'Untitled'
    return new Promise((resolve, reject) => {
      try {
        const companyUid = context.rootGetters['Company/companyUid']
        const cartObj = JSON.parse(localStorage.getItem(`_mpCart_${companyUid}`)) || []

        // The API loses the IXType on save, but does allow us to put in a resources object
        // which it will return when we ask for it later. Therefore, go through and put any
        // ixType objects into resources during save, and restore them later on load.
        for (const item of cartObj) {
          for (const ix of item.associatedIxs) {
            if (ix.ixType) {
              ix.resources = ix.ixType
            }
          }

          // The API modifies the content of the vendorConfig field (only available when the MVE is in DESIGN),
          // which is generating issues with the new MVE sizing. So, we store the vendorConfig we send
          // in the custom_properties object so that we can use it later when retrieving the data.
          // The API also does not accept the vendor field inside the vendorConfig when validating or buying an
          // MVE but we still need to send it when saving the configuration.
          if (item.productType === globals.G_PRODUCT_TYPE_MVE && item.provisioningStatus === globals.G_PROVISIONING_DESIGN) {
            item.config.custom_properties.vendorConfig = item.vendorConfig
            item.vendorConfig.vendor = item.vendorConfig._vendor
          }
        }

        sdk.instance
          .serviceOrder(serviceOrderUid)
          .save(title, cartObj)
          .then(sO => {
            if (!suppressSuccessMessages) {
              context.commit(
                'Notifications/notifyGood',
                {
                  title: window.mpApp.$t('sidebar.config-saved-title', { name: title }),
                  message: window.mpApp.$t('sidebar.config-saved-message', { name: title, serviceOrder: sO.serviceOrderUid }),
                },
                {
                  root: true,
                }
              )
            }
            context.dispatch('cartsFromServer')
            resolve(sO.serviceOrderUid)
          })
          .catch(e => {
            reject(e)
            // TODO: Improve error processing
            if (!e.handled) {
              context.commit(
                'Notifications/notifyBad',
                {
                  title: window.mpApp.$t('sidebar.config-save-error-title', { name: title }),
                  message: e.message,
                },
                {
                  root: true,
                }
              )
            }
          })
      } catch (e) {
        reject(e)
        context.commit(
          'Notifications/notifyBad',
          {
            title: window.mpApp.$t('sidebar.config-save-error-title', { name: title }),
            message: e,
          },
          {
            root: true,
          }
        )
      }
    })
  },

  moveCartContents(context, newCompanyUid) {
    const companyUid = newCompanyUid || context.rootGetters['Company/companyUid']

    // copy items for the previous company Uid into the profile for the current company Uid
    const cartObj = localStorage.getItem(`_mpCart_${globals.PUBLIC_COMPANY_UID}`)
    if (cartObj) {
      localStorage.setItem(`_mpCart_${companyUid}`, cartObj)
      localStorage.removeItem(`_mpCart_${globals.PUBLIC_COMPANY_UID}`)
    }
  },

  loadCart(context, serviceOrderParam) {
    let serviceOrder = serviceOrderParam
    if (typeof serviceOrder === 'string') {
      serviceOrder = context.state.serverCarts.find(cart => cart.serviceOrderUid === serviceOrder)
    }

    if (!serviceOrder) {
      return
    }

    context.commit('updateVendorConfig', serviceOrder.serviceRequestObject)

    // The API loses the IXType on save, but does allow us to put in a resources object
    // which it will return when we ask for it later. Therefore, go through and put any
    // ixType objects into resources during save, and restore them later on load.
    for (const item of serviceOrder.serviceRequestObject) {
      for (const ix of item.associatedIxs) {
        if (ix.resources) {
          ix.ixType = ix.resources
        }
      }
    }

    context.dispatch(
      'loadServicesFromCart',
      deepClone(serviceOrder.serviceRequestObject)
    )

    context.dispatch('actuallyRefreshServiceMetadata')
  },

  async clearCart(context) {
    try {
      const companyUid = context.rootGetters['Company/companyUid']
      const cartObj = JSON.parse(localStorage.getItem(`_mpCart_${companyUid}`)) || []
      for (const service of cartObj) {
        // First cleanup the VXCs, as later on when we cleanup services, removeServiceOrConnection also remove any VXCs on a service at the same time.
        // If we flip the order, we'll be trying to cleanup VXCs that potentially don't exist! So it's important to do this first when clearing the whole cart.
        service.associatedVxcs.concat(service.associatedIxs).forEach(connection => {
          if (connection.provisioningStatus === globals.G_PROVISIONING_DESIGN || service.provisioningStatus === globals.G_PROVISIONING_DESIGN_DEPLOY) {
            context.dispatch('removeServiceOrConnection', {
              productUid: connection.productUid,
              cartCleanup: false,
            })
          }
        })

        // Now that we've cleaned up any VXCs, we can cleanup the remaining services.
        if (service.provisioningStatus === globals.G_PROVISIONING_DESIGN || service.provisioningStatus === globals.G_PROVISIONING_DESIGN_DEPLOY) {
          context.dispatch('removeServiceOrConnection', {
            productUid: service.productUid,
            cartCleanup: false,
          })
        }
      }

      await context.dispatch('clearCartFromLocalStorage')
    } catch (e) {
      console.error(e)
    }
  },

  cartsFromServer(context) {
    sdk.instance
      .serviceOrder()
      .get()
      .then(carts => {
        for (const item of carts) {
          for (const requestObj of item.serviceRequestObject ) {
            if (requestObj.provisioningStatus === globals.G_PROVISIONING_DESIGN) {
              // Update the associatedIxs and associatedVxcs to include the parentPortUid as this isn't returned
              // by the server carts.
              requestObj.associatedIxs.forEach(connection => {
                connection.parentPortUid = [requestObj.productUid]
              })

              requestObj.associatedVxcs.forEach(connection => {
                const parentPortUid = [connection.aEnd.productUid]

                // Add bEndService if owned by the same company
                const bEndService = context.getters.portUidDictionary[connection.bEnd.productUid]
                if (bEndService) {
                  parentPortUid.push(bEndService.productUid)
                }

                connection.parentPortUid = parentPortUid
              })
              // The API modifies the content of the vendorConfig field (only available when the MVE is in DESIGN),
              // which is generating issues with the new MVE sizing. So here we restore the vendorConfig
              // we sent in the custom_properties object so that we could use it later when retrieving the data.
              if (requestObj.productType === globals.G_PRODUCT_TYPE_MVE && requestObj.config.custom_properties.vendorConfig) {
                requestObj.vendorConfig = requestObj.config.custom_properties.vendorConfig
              }

              // The API also changes the diversityZone field from camelCase into snake_case which causes utter chaos
              if ([globals.G_PRODUCT_TYPE_MCR2, globals.G_PRODUCT_TYPE_MVE, globals.G_PRODUCT_TYPE_MEGAPORT].includes(requestObj.productType) && requestObj.config.diversity_zone) {
                requestObj.config.diversityZone = requestObj.config.diversity_zone
                delete requestObj.config.diversity_zone
              }
            }
          }
        }
        context.commit('setServerCarts', carts)
      })
      .catch(e => {
        captureSentryError(e)
      })
  },

  clearCartFromLocalStorage(context) {
    const companyUid = context.rootGetters['Company/companyUid']
    localStorage.removeItem(`_mpCartId_${companyUid}`)
    localStorage.removeItem(`_mpCart_${companyUid}`)
  },

  setDeployingCart(context, { serviceOrderUid, deploying }) {
    const cartIndex = context.state.serverCarts.findIndex(cart => cart.serviceOrderUid === serviceOrderUid)

    if (cartIndex === -1) return

    context.commit('setServerCartDeploying', {
      index: cartIndex,
      deploying,
    })
  },

  deleteCartFromServer(context, { serviceOrder: serviceOrderParam, suppressSuccessMessages }) {
    if (!serviceOrderParam) {
      return
    }
    let serviceOrder = serviceOrderParam
    if (typeof serviceOrder === 'string') {
      serviceOrder = {
        serviceOrderUid: serviceOrder,
        serviceTitle: '',
      }
    }

    const eIndex = context.state.serverCarts.findIndex(cart => cart.serviceOrderUid === serviceOrder.serviceOrderUid)
    if (eIndex > -1) {
      context.commit('deleteServerCart', eIndex)
      sdk.instance
        .serviceOrder(serviceOrder.serviceOrderUid)
        .delete()
        .then(() => {
          if (!suppressSuccessMessages) {
            context.commit(
              'Notifications/notifyGood',
              {
                title: window.mpApp.$t('sidebar.config-deleted-title'),
              },
              {
                root: true,
              }
            )
          }
        })
        .catch(err => {
          // TODO: Improve error processing
          context.commit(
            'Notifications/notifyBad',
            {
              title: window.mpApp.$t('sidebar.config-delete-error-title', { name: serviceOrder.serviceTitle }),
              message: err.data?.message,
            },
            {
              root: true,
            }
          )
        })
    }
  },

  async moveConnection(context, connectionData) {
    // Submit move request to api
    await sdk.instance
      .product(connectionData.connectionUid)
      .update({
        [`${connectionData.affectedEnd}EndProductUid`]: connectionData.newEndUid,
        // The fields below are sent when configuring the vNic details for the destination MVE
        [`${connectionData.affectedEnd}VnicIndex`]: connectionData.vNicIndex,
        [`${connectionData.affectedEnd}EndVlan`]: connectionData.vlan,
        [`${connectionData.affectedEnd}EndInnerVlan`]: connectionData.innerVlan,
      })
    // We need to mirror the move in our local data so it shows up correctly in the service list
    context.commit('moveConnectionEnd', connectionData)
    context.dispatch('refreshServiceMetadata')
  },

  addCollapsedServiceUid(context, serviceUid) {
    if (!context.state.collapsedServiceUids.includes(serviceUid)) {
      context.commit('setCollapsedServiceUids', [...context.state.collapsedServiceUids, serviceUid])
    }
  },

  removeCollapsedServiceUid(context, serviceUid) {
    const index = context.state.collapsedServiceUids.indexOf(serviceUid)

    if (index >= 0) {
      context.commit('setCollapsedServiceUids', context.state.collapsedServiceUids.toSpliced(index, 1))
    }
  },

  setPeriodicRefreshEnabled(context, tf) {
    if (!tf) {
      context.commit('disablePeriodicRefresh')
    } else {
      // no need to periodicallyRefreshService since it will kick off for any required services from the
      // loading of the services.
      context.commit('enablePeriodicRefresh')
      context.dispatch('periodicallyRefreshServiceMetadata')
    }
  },

  periodicallyRefreshService(context, { serviceUid, incResources, nextCheckTimeout }) {
    const timerId = setTimeout(() => {
      if (context.state.pollingDisabled) return

      context.dispatch('fetchServiceOrConnection', {
          productUid: serviceUid,
          incResources,
          periodic: true,
        })
    }, nextCheckTimeout * 1000)
    context.commit('addFetchServiceTimeout', {
      serviceUid,
      timerId,
    })
  },

  periodicallyRefreshServiceMetadata(context) {
    const timeoutId = setTimeout(() => {
      context.dispatch('actuallyRefreshServiceMetadata')
    }, 500)
    context.commit('setRefreshPortsMetadataTimeout', timeoutId)
  },
}

const mutations = {
  /**
   * Reset the data that was relevant to the logged in user.
   *
   * @param {object} state - the local state
   */
  logout(state) {
    state.services = []
    state.serverCarts = []
    state.servicesReady = false
    for (const key of Object.keys(state.fetchServiceTimeouts)) {
      clearTimeout(state.fetchServiceTimeouts[key])
    }
    state.fetchServiceTimeouts = {}
    if (state.refreshPortsMetadataTimeout) {
      clearTimeout(state.refreshPortsMetadataTimeout)
      state.refreshPortsMetadataTimeout = null
    }
  },

  /**
   * Completely replace the partner ports.
   *
   * @param {object} state - the local state
   * @param {object} payload - the new partner ports list
   */
  setPartnerPorts(state, payload) {
    // Convert the list into an object
    const obj = {}
    if (payload) {
      for (const port of payload) {
        obj[port.productUid] = port
      }
    }

    // We use Object.freeze to disable reactivity on the partnerPorts object.
    // More info: https://www.ag-grid.com/vue-data-grid/framework-data-flow/#memory-footprint
    // This is a performance optimization to reduce memory usage.
    state.partnerPorts = Object.freeze(obj)
  },

  /**
   * Add a partner port to the array if it isn't already there.
   *
   * @param {object} state - the local state
   * @param {object} port - the new partner port
   */
  addPartnerPort(state, port) {
    if (state.partnerPorts[port.productUid]) {
      return
    }

    // We use Object.freeze to disable reactivity on the partnerPorts object.
    // More info: https://www.ag-grid.com/vue-data-grid/framework-data-flow/#memory-footprint
    // This is a performance optimization to reduce memory usage, freezing and overriding the object ends up being faster.
    state.partnerPorts = Object.freeze({ ...state.partnerPorts, [port.productUid]: {
      _location: state.locations.find(loc => loc.id === port.locationId) || {},
      ...port,
    } })
  },

  setLocations(state, payload) {
    // We use Object.freeze to disable reactivity on the locations array.
    // More info: https://www.ag-grid.com/vue-data-grid/framework-data-flow/#memory-footprint
    // This is a performance optimization to reduce memory usage.
    state.locations = Object.freeze(payload)
  },

  setTransitEnabledMarkets(state, payload) {
    state.transitEnabledMarkets = payload
  },

  clearServices(state) {
    state.services = []
  },

  setServices(state, payload) {
    state.services = payload
  },

  addService(state, payload) {
    state.services.push(payload)
  },

  updateService(state, { index, newService }) {
    const serviceIndex = index || state.services.findIndex(port => port.productUid === newService.productUid)
    Vue.set(state.services, serviceIndex, newService)
  },

  updateVXC(state, { service, connection }) {
    const vxcIndex = service.associatedVxcs.findIndex(vxc => vxc.productUid === connection.productUid)
    if (vxcIndex !== -1) {
      Vue.set(service.associatedVxcs, vxcIndex, connection)
    }
  },

  updateIX(state, { service, connection }) {
    const ixIndex = service.associatedIxs.findIndex(ix => ix.productUid === connection.productUid)
    if (ixIndex !== -1) {
      Vue.set(service.associatedIxs, ixIndex, connection)
    }
  },

  deleteService(state, index) {
    state.services.splice(index, 1)
  },

  changeProvisioningStatus(state, { serviceUid, status }) {
    // Filter the given service from all services and their associated VXCs and IXs
    const services = state.services
      .concat(...state.services.map(port => [...port.associatedVxcs, ...port.associatedIxs]))
      .filter(service => service.productUid === serviceUid)
    // Change the provisioning status of the service in all instances where it is found within the services array
    services.forEach(service => {
      service.provisioningStatus = status
    })
  },

  setServerCarts(state, payload) {
    state.serverCarts = payload
  },

  setServerCartDeploying(state, { index, deploying }) {
    state.serverCarts[index].deploying = deploying
  },

  deleteServerCart(state, index) {
    state.serverCarts.splice(index, 1)
  },

  addAssociatedVxc(state, { service, vxc }) {
    service.associatedVxcs.push(vxc)
  },

  removeAssociatedVxc(state, { service, index }) {
    service.associatedVxcs.splice(index, 1)
  },

  addAssociatedIx(state, { service, ix }) {
    service.associatedIxs.push(ix)
  },

  removeAssociatedIx(state, { service, index }) {
    service.associatedIxs.splice(index, 1)
  },

  setLockedState(state, { obj, locked }) {
    // Make sure we get all entries for the connection
    const services = state.services
      .concat(...state.services.map(port => {
        const arr = [...port.associatedVxcs, ...port.associatedIxs]
        if (port._subLags) {
          return arr.concat(...port._subLags)
        }
        return arr
      }))
      .filter(service => service.productUid === obj.productUid)
    if (services) {
      for (const service of services) {
        service.locked = locked
      }
    }
  },

  removeFetchServiceTimeout(state, serviceUid) {
    if (state.fetchServiceTimeouts[serviceUid]) {
      clearTimeout(state.fetchServiceTimeouts[serviceUid])
      delete state.fetchServiceTimeouts[serviceUid]
    }
  },

  addFetchServiceTimeout(state, { serviceUid, timerId }) {
    const existingTimer = state.fetchServiceTimeouts[serviceUid]
    if (existingTimer) clearTimeout(existingTimer)

    state.fetchServiceTimeouts[serviceUid] = timerId
  },

  setRefreshPortsMetadataTimeout(state, newValue) {
    state.refreshPortsMetadataTimeout = newValue
  },

  fixLocationIds(state, existingPort) {
    // Make sure the location ID at both ends of every VXC connection is set.
    for (const vxc of existingPort.associatedVxcs) {
      // Set the location ID for the A-End
      if (vxc.aEnd.productUid === existingPort.productUid && vxc.aEnd.locationId !== existingPort.locationId) {
        vxc.aEnd.locationId = existingPort.locationId
      }
      // Set the location ID for the B-End
      if (vxc.bEnd.productUid === existingPort.productUid && vxc.bEnd.locationId !== existingPort.locationId) {
        vxc.bEnd.locationId = existingPort.locationId
      }
    }

    // Set the location ID for IX connections.
    for (const ix of existingPort.associatedIxs) {
      if (ix.locationId !== existingPort.locationId) {
        ix.locationId = existingPort.locationId
      }
    }
  },

  disablePeriodicRefresh(state) {
    state.pollingDisabled = true
    for (const key of Object.keys(state.fetchServiceTimeouts)) {
      clearTimeout(state.fetchServiceTimeouts[key])
    }
    state.fetchServiceTimeouts = {}
    if (state.refreshPortsMetadataTimeout) {
      clearTimeout(state.refreshPortsMetadataTimeout)
      state.refreshPortsMetadataTimeout = null
    }
  },

  enablePeriodicRefresh(state) {
    state.pollingDisabled = false
  },

  setReadyStatus(state, readyObj) {
    state[readyObj.key] = readyObj.status
  },

  moveConnectionEnd(state, { connectionUid, affectedEnd, oldEndUid, newEndUid }) {
    // Locate the service records for the ends
    const oldEndIndex = state.services.findIndex(service => service.productUid === oldEndUid)
    if (oldEndIndex < 0) return
    const newEndIndex = state.services.findIndex(service => service.productUid === newEndUid)
    if (newEndIndex < 0) return

    // Search the vxc list
    const vxcIndex = state.services[oldEndIndex].associatedVxcs.findIndex(associatedVxc => associatedVxc.productUid === connectionUid)
    if (vxcIndex >= 0) {
      const [vxc] = state.services[oldEndIndex].associatedVxcs.splice(vxcIndex, 1)
      // Update other end data on VXC
      const newEndData = state.services[newEndIndex]
      vxc[`${affectedEnd}End`] = {
        // The "location" field with the location name will be outdated, but it's not worth trying to fix as there's
        // only 1 place in code relying upon it
        ...vxc[`${affectedEnd}End`],
        productUid: newEndData.productUid,
        productName: newEndData.productName,
        locationId: newEndData.locationId,
      }
      state.services[newEndIndex].associatedVxcs.push(vxc)

      // We also need to update the end that *didn't* move as the vxc's end data will be outdated
      const staticEndUid = vxc[`${affectedEnd === 'b' ? 'a' : 'b'}End`].productUid
      const staticEndIndex = state.services.findIndex(service => service.productUid === staticEndUid)
      // If we're lucky, we don't own the static end and no update is necessary
      if (staticEndIndex < 0) return
      const staticVxcIndex = state.services[staticEndIndex].associatedVxcs.findIndex(associatedVxc => associatedVxc.productUid === connectionUid)
      if (staticVxcIndex >= 0) {
        state.services[staticEndIndex].associatedVxcs[staticVxcIndex] = vxc
      }

    } else {
      // Try to find in the ix list instead
      const ixIndex = state.services[oldEndIndex].associatedIxs.findIndex(ix => ix.productUid === connectionUid)
      if (ixIndex < 0) return
      const [ix] = state.services[oldEndIndex].associatedIxs.splice(ixIndex, 1)
      state.services[newEndIndex].associatedIxs.push(ix)
    }
  },

  /**
   * For any MVE configurations saved prior to the addition of the productCode field in the vendorConfig object,
   * we need to ensure that it is compatible with the newer configuration shape and will load properly.
   * @param {Array<Object>} services An array of in-design services
   */
  updateVendorConfig(_state, services) {
    for (const service of services) {
      if (service.productType === globals.G_PRODUCT_TYPE_MVE
          && !service.vendorConfig?._productCode
          && service.provisioningStatus === globals.G_PROVISIONING_DESIGN) {
        service.vendorConfig._vendor = service.vendorConfig.vendor

        let productCode = ''

        // Set the product code based on the vendor
        // No need to worry about Cisco FTDv as no prior configurations would contain it
        switch (service.vendorConfig.vendor) {
          case globals.ARUBA_VENDOR:
            productCode = globals.ARUBA_PRODUCT
            break
          case globals.CISCO_VENDOR:
            productCode = globals.CISCO_c8000_PRODUCT
            break
          case globals.FORTINET_VENDOR:
            productCode = globals.FORTINET_PRODUCT
            break
          case globals.PALO_ALTO_VENDOR:
            productCode = globals.PALO_ALTO_PRODUCT
            break
          case globals.VMWARE_VENDOR:
            productCode = globals.VMWARE_PRODUCT
            break
          case globals.VERSA_VENDOR:
            productCode = globals.VERSA_PRODUCT
            break
        }

        service.vendorConfig._productCode = productCode
      }
    }
  },

  setCollapsedServiceUids(state, payload) {
    state.collapsedServiceUids = payload
  },

  setParentPortUidOnConnection(state, { connection, parentPortUid }) {
    connection.parentPortUid = parentPortUid
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
}
